<template>
  <div class="flex flex-col pb-6 px-4 lg:px-6 lg:overflow-y-scroll lg:h-screen pb-10 mt-20 lg:mt-0">
    <Steps
      ref="stepRef"
      :items="steps"
      :step-clicked="stepChanged"
      :stepIndex="selectedStepIndex"
    />
    <div class="w-full">
      <div class="py-10 max-w-7xl mx-auto">
        <div class="grid grid-cols-3 gap-4">
          <div
            class="row-same-height left-block-main hidden sm:block"
            v-if="displaySummary ? selectedStepIndex + 1 < steps.length : true"
          >
            <!--						<div class="relative">LEFT PART</div>-->
            <HelpView
              v-bind="{
                label: 'Need help? Click on Ed!',
                helpHtml,
              }"
            />
          </div>
          <div
            class="col-span-3 sm:col-span-2 h-full overflow-y-scroll"
            v-bind:class="{
              'border-l border-white sm:border-gray-300': displaySummary
                ? selectedStepIndex + 1 < steps.length
                : true,
            }"
          >
            <ApplicationForm
              ref="formRef"
              :form="steps[selectedStepIndex]"
              class=""
              @onFocus="(html) => (helpHtml = html)"
            />
            <div
              class="flex justify-end sm:justify-start pr-4 sm:pr-0 pl-10 pt-4 pb-2 space-x-3 bottom-2 right-2"
              v-if="
                displaySummary ? selectedStepIndex + 1 < steps.length : true
              "
            >
              <Button
                variant="secondary"
                size="lg"
                content="Back"
                :disabled="selectedStepIndex === 0"
                :click="previousClicked"
              />
              <Button
                variant="themed"
                size="lg"
                content="Next"
                :click="nextClicked"
              />
              <!--<Button
								v-if="selectedStepIndex + 1 === steps.length"
								variant="themed"
								size="lg"
								content="Submit"
								:click="submitForm"
								:is-loading="isLoading"
							/>-->
            </div>
          </div>
        </div>
        <div
          class="px-0 lg:px-10"
          v-if="displaySummary && selectedStepIndex + 1 === steps.length"
        >
          <Summary
            btnLabel="FINISH"
            :tableBoxProps="summary"
            :onFinish="submitForm"
            @onEditRow="(index) => stepChanged(steps, index)"
          />
        </div>
      </div>
    </div>
    <!-- <div class="w-full">
			<ApplicationForm
				ref="formRef"
				:form="steps[selectedStepIndex]"
				class="py-10 max-w-7xl mx-auto"
			/>
		</div> -->
    <!-- <div class="flex absolute space-x-3 bottom-2 right-2">
			<Button
				variant="secondary"
				size="lg"
				content="Back"
				:disabled="selectedStepIndex === 0"
				:click="previousClicked"
			/>
			<Button
				v-if="selectedStepIndex < steps.length - 1"
				variant="themed"
				size="lg"
				content="Next"
				:click="nextClicked"
			/>
			<Button
				v-else
				variant="themed"
				size="lg"
				content="Submit"
				:click="submitForm"
				:is-loading="isLoading"
			/>
		</div> -->
  </div>
</template>

<script>
import Steps from "../../molecules/Steps/Simple/Simple.vue";
import Button from "../../atoms/Button/Button.vue";
import { ref } from "vue";
import ApplicationForm from "../../organisms/FormLayouts/ApplicationForm/ApplicationForm.vue";
import Summary from "../../organisms/Summary/Summary.vue";
import HelpView from "@/components/organisms/ContentSections/HelpView/HelpView";

export default {
  components: {
    HelpView,
    Steps,
    ApplicationForm,
    Button,
    Summary,
  },
  mounted() {
    this.setFormData(this.formData);
  },
  watch: {
    formData: function (data) {
      this.setFormData(data);
    },
    items: function (val) {
      this.steps = val;
    },
  },
  data() {
    return {
      helpHtml: this.steps[this.selectedStepIndex]?.questions[0]?.helpBlurb,
    };
  },
  computed: {
    summary() {
      var summary = [],
        form = this.$refs?.formRef?.getFormObject()
          ? { ...this.$refs?.formRef?.getFormObject() }
          : {};
      const steps = JSON.parse(JSON.stringify(this.steps));
      steps.forEach((step, i) => {
        if (step.questions) {
          var stepInfo = { title: step.name, rows: [] };
          step.questions.forEach((question) => {
            if (question && question.question) {
              const ques = Object.keys(form).find(
                (item) => item === question.question
              );
              var val = form[ques]
              if(val === undefined && 
                (question.question === 'WHAT IS YOUR CURRENT BALANCE OF CASH, CHECKING, AND SAVINGS ACCOUNTS?' || question.question === 'HOW MUCH MONEY DID YOU EARN FROM WORKING IN 2022?'
                || question.question === 'WHAT WAS YOUR PARENTS ADJUSTED GROSS INCOME IN 2022?' ||question.question === 'HOW MUCH INCOME TAX DID YOUR PARENTS PAY IN 2022?'
                || question.question === `WHAT IS YOUR PARENT'S CURRENT BALANCE OF CASH, CHECKING AND SAVINGS ACCOUNTS?` || question.question === `WHAT IS THE CURRENT BALANCE YOUR PARENTS HAVE IN EDUCATIONAL ACCOUNTS`)) val = 0
              stepInfo.rows.push({
                label: question.question,
                value: val
              });
            }
          });
          if (i === 2) {
            form.parentsOwnedFarmOrLand?.map((p, i) => {
              if (p?.isHome) {
                stepInfo.rows.push({
                  label: `DO YOUR PARENTS OWN ${
                    i === 0 ? "A" : "ANOTHER"
                  } HOME?`,
                  value: "Yes",
                });
                stepInfo.rows.push({
                  label: "WHAT IS THE VALUE OF THIS HOME?",
                  value: p?.valueOfFarmOrLand,
                });
                stepInfo.rows.push({
                  label: "WHAT IS THE TOTAL MORTGAGE DEBT?",
                  value: p?.mortgagePayoff,
                });
              }
            });
          }
          summary.push(stepInfo);
        }
      });
      return summary;
    },
    displaySummary() {
      return this.isSummary;
    },
  },
  methods: {
    setFormData(data) {
      const form = this.$refs?.formRef,
        booleanObj = {};
      if (data && form) {
        if (form.setFormObject) form.setFormObject({ ...data });
        if (form.setBooleanObject) {
          booleanObj[`DO YOUR PARENTS OWN A HOME?`] = data[
            `DO YOUR PARENTS OWN A HOME?`
          ]
            ? "Yes"
            : "No";
          booleanObj[
            `IN 2022 OR 2023, HAS ANYONE IN YOUR HOME RECEIVED BENEFITS FROM ANY DESIGNATED FEDERAL BENEFIT PROGRAMS?`
          ] = data[
            `IN 2022 OR 2023, HAS ANYONE IN YOUR HOME RECEIVED BENEFITS FROM ANY DESIGNATED FEDERAL BENEFIT PROGRAMS?`
          ]
            ? "Yes"
            : "No";
          booleanObj[`ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?`] = data[
            `ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?`
          ]
            ? "Yes"
            : "No";
          booleanObj[`DID YOU HAVE A JOB IN 2022?`] =
            data[`DID YOU HAVE A JOB IN 2022?`]?.toString();
          form.setBooleanObject({ ...booleanObj });
        }
      }
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    next: {
      type: Function,
      default: () => {},
    },
    previous: {
      type: Function,
      default: () => {},
    },
    stepChange: {
      type: Function,
      default: () => {},
    },
    submit: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => null,
    },
    isSummary: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const steps = ref(props.items);
    const selectedStepIndex = ref(0);
    const stepRef = ref();
    const formRef = ref();
    let surveyAnswersMap = {};
    const stepChanged = (itemsArray, index) => {
      steps.value = itemsArray;
      selectedStepIndex.value = index;
      surveyAnswersMap = formRef.value.getFormObject();
      props.stepChange(surveyAnswersMap);
      console.log(surveyAnswersMap);
      steps.value[2].status = "pending";
    };
    const nextClicked = () => {
      surveyAnswersMap = formRef.value.getFormObject();
      stepRef.value.nextClicked();
      const parentsOwnedFarmOrLand = {
        homeData: formRef.value.getHomeQuestions(),
        farmData: formRef.value.getFarmQuesttions(),
      };
      props.next({ ...surveyAnswersMap, parentsOwnedFarmOrLand });
    };
    const previousClicked = () => {
      surveyAnswersMap = formRef.value.getFormObject();
      stepRef.value.previousClicked();
      props.previous(surveyAnswersMap);
    };
    const submitForm = () => {
      console.log("submit");
      surveyAnswersMap = formRef.value.getFormObject();
      const parentsOwnedFarmOrLand = {
        homeData: formRef.value.getHomeQuestions(),
        farmData: formRef.value.getFarmQuesttions(),
      };
      props.submit({ ...surveyAnswersMap, parentsOwnedFarmOrLand });
    };

    return {
      steps,
      stepRef,
      formRef,
      stepChanged,
      nextClicked,
      previousClicked,
      submitForm,
      selectedStepIndex,
    };
  },
};
</script>

<style lang="css" scoped></style>
