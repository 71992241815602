<template>
  <Template
    v-bind="{
      ...params,
      items: getSteps(),
      formData: getFormData(),
      isSummary: this.isSummary,
      isLoading,
    }"
    :next="onNext"
    :submit="onSubmit"
  />
</template>
  <script>
import { _ } from "vue-underscore";
import Template from "../components/templates/ApplicationForm/ApplicationForm";
import { c } from "@/components/constants.js";
import { isUndefined, remove } from "lodash";
import moment from "moment";

export default {
  components: {
    Template,
  },
  data() {
    return {
      isLoading: false,
      params: c.application,
      schoolYears: [
        { name: "Freshman (1st year)", value: "FRESHMAN" },
        { name: "Sophomore (2nd year)", value: "SOPHOMORE" },
        { name: "Junior (3rd year)", value: "JUNIOR" },
        { name: "Senior (4th year)", value: "SENIOR" },
      ],
      contributingParents: [
        { name: "1 Parent, 1 Income", value: "ONE_PARENT_ONE_INCOME" },
        { name: "2 Parents, 1 Income", value: "TWO_PARENTS_ONE_INCOME" },
        { name: "2 Parents, 2 Incomes", value: "TWO_PARENTS_TWO_INCOMES" },
      ],
      households: [
        { name: "Two", value: "TWO" },
        { name: "Three", value: "THREE" },
        { name: "Four", value: "FOUR" },
        { name: "Five", value: "FIVE" },
        { name: 'Six', value: 'SIX' },
        { name: 'Seven', value: 'SEVEN' },
        { name: 'Eight', value: 'EIGHT' },
      ],
      numOfFamilyMembersInCollege: [
        { name: "None", value: 0 },
        { name: "One", value: 1 },
        { name: "Two", value: 2 },
        { name: "Three", value: 3 },
        { name: "Four", value: 4 },
        { name: "Five", value: 5 },
        { name: "Six or more", value: 6 },
      ],
      taxes: [
        { name: "1040", value: "IRS_1040" },
        // { name: "1040A", value: "IRS_1040A" },
        // { name: "1040EZ", value: "IRS_1040EZ" },
        { name: "Not Required To File", value: "NOT_REQUIRED_TO_FILE" },
      ],
      countryList: [{ name: "California", value: "CA" }],
      isSummary: false,
    };
  },
  methods: {
    getSteps() {
      const steps = [...c.application.items];
      if (this?.state?.currentFinApp?.id) this.isSummary = true;
      else steps.splice(steps.length - 1, 1);
      return steps;
    },
    getFormData() {
      const formData = {};
      console.log(this.state.currentFinApp, "currentFinApp");
      if (this.state.currentFinApp && this.state.currentFinApp.id) {
        if (this.state.currentUser?.dateOfBirth) {
          formData[`What is your birthday?`] = moment(
            this.state.currentUser.dateOfBirth
          ).format("MMDDYYYY");
        }
        if (this.state.currentFinApp.highSchoolYear) {
          formData[`WHAT YEAR OF HIGH SCHOOL ARE YOU IN?`] =
            this.schoolYears.find(
              (s) => s.value === this.state.currentFinApp.highSchoolYear
            )?.name;
        }
        formData[`WHAT IS YOUR STATE OF LEGAL RESIDENCE?`] = this.state
          .currentFinApp.residenceState
          ? this.countryList.find(
              (i) => i.value === this.state.currentFinApp.residenceState
            )?.name
          : this.countryList[0].name;
        if (this.state.currentFinApp.currentBalance) {
          formData[
            `WHAT IS YOUR CURRENT BALANCE OF CASH, CHECKING, AND SAVINGS ACCOUNTS?`
          ] = this.state.currentFinApp.currentBalance;
        }
        if (this.state.currentFinApp.liquidAssets) {
          formData[
            `WHAT IS YOUR PARENT'S CURRENT BALANCE OF CASH, CHECKING AND SAVINGS ACCOUNTS?`
          ] = this.state.currentFinApp.liquidAssets;
        }
        if (this.state.currentFinApp.assetEducationalAccounts) {
          formData[
            `WHAT IS THE CURRENT BALANCE YOUR PARENTS HAVE IN EDUCATIONAL ACCOUNTS`
          ] = this.state.currentFinApp.assetEducationalAccounts;
        }
        if (this.state.currentFinApp.earnedIncome) {
          formData[`HOW MUCH MONEY DID YOU EARN FROM WORKING IN 2022?`] =
            this.state.currentFinApp.earnedIncome;
        }
        if (this.state.currentFinApp.numOfContributingParents) {
          formData[`HOW MANY PARENTS CONTRIBUTE TO YOUR FAMILY'S EXPENSES?`] =
            this.contributingParents.find(
              (h) =>
                h.value === this.state.currentFinApp.numOfContributingParents
            )?.name;
        }
        if (this.state.currentFinApp.peopleInHousehold) {
          formData[`HOW MANY PEOPLE ARE IN YOUR HOUSEHOLD?`] =
            this.households.find(
              (h) => h.value === this.state.currentFinApp.peopleInHousehold
            )?.name;
        }
        if (
          !isUndefined(this.state.currentFinApp?.numOfFamilyMembersInCollege)
        ) {
          formData[
            `HOW MANY OTHERS FROM YOUR HOUSEHOLD WILL BE IN COLLEGE AT THE SAME TIME AS YOU?`
          ] = this.numOfFamilyMembersInCollege.find(
            (h) =>
              h.value === this.state.currentFinApp.numOfFamilyMembersInCollege
          )?.name;
        }
        if (this.state.currentFinApp.bornedYearOfEldestParent) {
          formData[`IN WHAT YEAR WAS YOUR OLDEST PARENT BORN?`] =
            this.state.currentFinApp.bornedYearOfEldestParent;
        }
        if (this.state.currentFinApp.parentsTaxForm) {
          formData[
            `WHICH FORM DID YOUR PARENTS USE TO FILE THEIR TAXES IN 2022?`
          ] = this.taxes.find(
            (t) => t.value === this.state.currentFinApp.parentsTaxForm
          )?.name;
        }
        if (this.state.currentFinApp.adjustedGrossIncome) {
          formData[`WHAT WAS YOUR PARENTS ADJUSTED GROSS INCOME IN 2022?`] =
            this.state.currentFinApp.adjustedGrossIncome;
        }
        if (this.state.currentFinApp.incomeTaxPaid) {
          formData[`HOW MUCH INCOME TAX DID YOUR PARENTS PAY IN 2022?`] =
            this.state.currentFinApp.incomeTaxPaid;
        }
        formData["parentsOwnedFarmOrLand"] =
          this.state.currentFinApp?.parentsOwnedFarmOrLand || [];
        if (
          this.state.currentUser.phones &&
          this.state.currentUser.phones.length > 0
        )
          formData[`WHAT IS YOUR PHONE NUMBER?`] =
            this.state.currentUser.phones[
              this.state.currentUser.phones.length - 1
            ]?.number;
        formData[
          `IN 2022 OR 2023, HAS ANYONE IN YOUR HOME RECEIVED BENEFITS FROM ANY DESIGNATED FEDERAL BENEFIT PROGRAMS?`
        ] = this.state.currentFinApp?.receivedFederalBenefits;
        formData[`ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?`] =
          this.state.currentFinApp?.isParentDislocatedWorker;
        formData[`DID YOU HAVE A JOB IN 2022?`] =
          this.state.currentFinApp.hadJob || false;
      } else {
        formData[`WHAT IS YOUR STATE OF LEGAL RESIDENCE?`] =
          this.countryList[0].name;
      }
      return formData;
    },
    async onSubmit(item) {
      //   console.log(item, "SUBMIT");
      this.saveAppFn(item, "submit");
      //   try {
      //     this.isLoading = true;
      //     const params = {
      //       data: {
      //         highSchoolYear: this.schoolYears.find(
      //           (s) => s.name === item["WHAT YEAR OF HIGH SCHOOL ARE YOU IN?"]
      //         )?.value,
      //         residenceState: item["WHAT IS YOUR STATE OF LEGAL RESIDENCE?"],
      //         currentBalance:
      //           item[
      //             "WHAT IS YOUR CURRENT BALANCE OF CASH, CHECKING, AND SAVINGS ACCOUNTS?"
      //           ],
      //         hadJob: item["DID YOU HAVE A JOB IN 2022?"],
      //         earnedIncome:
      //           item["HOW MUCH MONEY DID YOU EARN FROM WORKING IN 2022?"],
      //         numOfContributingParents: this.contributingParents.find(
      //           (c) =>
      //             c.name ===
      //             item["HOW MANY PARENTS CONTRIBUTE TO YOUR FAMILY'S EXPENSES?"]
      //         )?.value,
      //         peopleInHousehold: this.households.find(
      //           (h) =>
      //             h.name ===
      //             item[
      //               "HOW MANY OTHERS FROM YOUR HOUSEHOLD WILL BE IN COLLEGE AT THE SAME TIME AS YOU?"
      //             ]
      //         )?.value,
      //         bornedYearOfEldestParent: parseInt(
      //           item["IN WHAT YEAR WAS YOUR OLDEST PARENT BORN?"]
      //         ),
      //         parentsTaxForm: this.taxes.find(
      //           (t) =>
      //             t.name ===
      //             item[
      //               "WHICH FORM DID YOUR PARENTS USE TO FILE THEIR TAXES IN 2022?"
      //             ]
      //         )?.value,
      //         adjustedGrossIncome:
      //           item["WHAT WAS YOUR PARENTS ADJUSTED GROSS INCOME IN 2022?"],
      //         incomeTaxPaid:
      //           item["HOW MUCH INCOME TAX DID YOUR PARENTS PAY IN 2022?"],
      //         receivedFederalBenefits:
      //           item[
      //             "IN 2022, HAS ANYONE IN YOUR HOME RECEIVED BENEFITS FROM ANY DESIGNATED FEDERAL BENEFIT PROGRAMS?"
      //           ],
      //         parentsOwnedFarmOrLand: {
      //           create: [
      //             {
      //               valueOfFarmOrLand: item["WHAT IS THE VALUE OF THIS HOME?"],
      //               mortgagePayoff: item["WHAT IS THE TOTAL MORTGAGE DEBT?"],
      //             },
      //             {
      //               valueOfFarmOrLand:
      //                 item[
      //                   "WHAT IS THE VALUE OF THEIR HOME, FARM, OR PIECE OF LAND?"
      //                 ],
      //               mortgagePayoff: item["WHAT IS THE TOTAL MORTGAGE DEBT?"],
      //             },
      //           ],
      //         },
      //         isActive: true,
      //         user: {
      //           connect: {
      //             id: this.state.currentUser?.id,
      //           },
      //         },
      //       },
      //     };
      //     const { saveFinancialApplication } =
      //       await this.actions.financialApplication.saveFinancialApplication(
      //         params
      //       );
      //     //
      //     const user = this.state.currentUser;
      //     const phoneNumber = item["WHAT IS YOUR PHONE NUMBER?"];
      //     if (phoneNumber && user && user.id) {
      //       if (!user.phones.find((i) => i.number == phoneNumber)) {
      //         const { saveUser } = await this.actions.user.saveUser({
      //           where: { id: user?.id },
      //           data: {
      //             phones: {
      //               create: [{ number: phoneNumber }],
      //             },
      //           },
      //         });
      //       }
      //     }
      //     //
      //     if (saveFinancialApplication?.id) {
      //       this.actions.alert.showSuccess({
      //         message: "Saved data successfully",
      //       });
      //     }
      //   } catch (e) {
      //     console.log(e);
      //     this.actions.alert.showError({
      //       message: "Failed to save data. Please try again.",
      //     });
      //   } finally {
      //     this.isLoading = false;
      //   }
    },
    async onNext(item) {
      this.saveAppFn(item, "next");
    },
    async saveAppFn(item, type) {
      console.log(item, "saveAppFn");
      try {
        this.isLoading = true;
        const self = this;
        // console.log(item, "NEXT");
        const app = self.state.currentFinApp;
        const user = self.state.currentUser;
        //
        const params = {
          data: { isActive: true },
        };
        if (app) params["where"] = { id: app.id };
        else params["data"]["user"] = { connect: { id: user.id } };
        //
        var date = null;
        if (item["What is your birthday?"]) {
          const v = item["What is your birthday?"];
          const month = parseInt(v.substring(0, 2));
          const day = parseInt(v.substring(2, 4));
          const year = parseInt(v.substring(4, 8));
          if (month && month <= 12 && day && day <= 31 && year) {
            date = new Date(year, month - 1, day);
          } else {
            this.actions.alert.showError({
              message: "Please enter valid birth date",
            });
            return;
          }
        }
        _.each(item, (v, i) => {
          // console.log(i, v);
          if (i === "WHAT YEAR OF HIGH SCHOOL ARE YOU IN?")
            params.data.highSchoolYear = self.schoolYears.find(
              (s) => s.name === v
            )?.value;
          if (i === "WHAT IS YOUR STATE OF LEGAL RESIDENCE?")
            params.data.residenceState = self.countryList.find(
              (i) => i.name === v
            )?.value;
          if (
            i ===
            "WHAT IS YOUR CURRENT BALANCE OF CASH, CHECKING, AND SAVINGS ACCOUNTS?"
          )
            params.data.currentBalance = v;
          if (
            i ===
            "WHAT IS YOUR PARENT'S CURRENT BALANCE OF CASH, CHECKING AND SAVINGS ACCOUNTS?"
          )
            params.data.liquidAssets = v;
          if (
            i ===
            "WHAT IS THE CURRENT BALANCE YOUR PARENTS HAVE IN EDUCATIONAL ACCOUNTS"
          )
            params.data.assetEducationalAccounts = v;
          if (i === "DID YOU HAVE A JOB IN 2022?") params.data.hadJob = v;
          if (i === "HOW MUCH MONEY DID YOU EARN FROM WORKING IN 2022?")
            params.data.earnedIncome = v;
          if (i === "HOW MANY PARENTS CONTRIBUTE TO YOUR FAMILY'S EXPENSES?")
            params.data.numOfContributingParents =
              self.contributingParents.find((c) => c.name === v)?.value;
          if (i === "HOW MANY PEOPLE ARE IN YOUR HOUSEHOLD?")
            params.data.peopleInHousehold = self.households.find(
              (h) => h.name === v
            )?.value;
          if (
            i ===
            "HOW MANY OTHERS FROM YOUR HOUSEHOLD WILL BE IN COLLEGE AT THE SAME TIME AS YOU?"
          )
            params.data.numOfFamilyMembersInCollege =
              self.numOfFamilyMembersInCollege.find((h) => h.name === v)?.value;
          if (i === "IN WHAT YEAR WAS YOUR OLDEST PARENT BORN?")
            params.data.bornedYearOfEldestParent = parseInt(v);
          if (
            i === "WHICH FORM DID YOUR PARENTS USE TO FILE THEIR TAXES IN 2022?"
          )
            params.data.parentsTaxForm = self.taxes.find(
              (t) => t.name === v
            )?.value;
          if (i === "WHAT WAS YOUR PARENTS ADJUSTED GROSS INCOME IN 2022?")
            params.data.adjustedGrossIncome = v;
          if (i === "HOW MUCH INCOME TAX DID YOUR PARENTS PAY IN 2022?")
            params.data.incomeTaxPaid = v;
          if (
            i ===
            "IN 2022 OR 2023, HAS ANYONE IN YOUR HOME RECEIVED BENEFITS FROM ANY DESIGNATED FEDERAL BENEFIT PROGRAMS?"
          )
            params.data.receivedFederalBenefits = v;
          if (i === "ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?")
            params.data.isParentDislocatedWorker = v;
          if (i === "What is your birthday?" && app) {
            params.data.user = { update: { dateOfBirth: date } };
          }
        });
        const homeData =
          app && app.parentsOwnedFarmOrLand
            ? app.parentsOwnedFarmOrLand.filter((item) => item.isHome === true)
            : [];
        const farmOrLandData =
          app && app.parentsOwnedFarmOrLand
            ? app.parentsOwnedFarmOrLand.filter((item) => !item.isHome)
            : [];
        console.log(farmOrLandData, "farmOrLandData");
        if (
          item["parentsOwnedFarmOrLand"].farmData?.length > 0 ||
          item["parentsOwnedFarmOrLand"].homeData?.length > 0
        ) {
          var farmOrLandDataParams = {
            update: [],
            create: [],
            delete: [],
            where: params.where,
          };
          //home
          item["parentsOwnedFarmOrLand"].homeData.map((home, index) => {
            if (
              home &&
              ((home[1] && home[1].answer) || (home[2] && home[2].answer))
            ) {
              if (homeData[index]) {
                farmOrLandDataParams.update.push({
                  where: { id: homeData[index].id },
                  data: {
                    valueOfFarmOrLand: home[1].answer,
                    mortgagePayoff: home[2].answer,
                  },
                });
              } else {
                farmOrLandDataParams.create.push({
                  valueOfFarmOrLand: home[1].answer,
                  mortgagePayoff: home[2].answer,
                  isHome: true,
                });
              }
            }
          });
          if (
            homeData.length >
            item["parentsOwnedFarmOrLand"].homeData.length - 1
          ) {
            const removeHomeData = [...homeData].splice(
              item["parentsOwnedFarmOrLand"].homeData.length - 1,
              homeData.length
            );
            removeHomeData.map((home) => {
              farmOrLandDataParams.delete.push({ id: home.id });
            });
          }

          //farm
          item["parentsOwnedFarmOrLand"].farmData.map((farm, index) => {
            if (
              farm &&
              ((farm[1] && farm[1].answer) || (farm[2] && farm[2].answer))
            ) {
              if (farmOrLandData[index]) {
                farmOrLandDataParams.update.push({
                  where: { id: farmOrLandData[index].id },
                  data: {
                    valueOfFarmOrLand: farm[1].answer,
                    mortgagePayoff: farm[2].answer,
                  },
                });
              } else {
                farmOrLandDataParams.create.push({
                  valueOfFarmOrLand: farm[1].answer,
                  mortgagePayoff: farm[2].answer,
                  isHome: false,
                });
              }
            }
          });
          if (
            farmOrLandData.length >
            item["parentsOwnedFarmOrLand"].farmData.length - 1
          ) {
            const removeFarmOrLandData = [...farmOrLandData].splice(
              item["parentsOwnedFarmOrLand"].farmData.length - 1,
              farmOrLandData.length
            );
            removeFarmOrLandData.map((farm) => {
              farmOrLandDataParams.delete.push({ id: farm.id });
            });
          }

          console.log(farmOrLandDataParams, "farmOrLandDataParams");
          if (farmOrLandDataParams.update.length > 0) {
            await self.actions.financialApplication.saveFinancialApplication({
              data: {
                parentsOwnedFarmOrLand: { update: farmOrLandDataParams.update },
              },
              where: farmOrLandDataParams.where,
            });
          }
          if (farmOrLandDataParams.create.length > 0) {
            await self.actions.financialApplication.saveFinancialApplication({
              data: {
                parentsOwnedFarmOrLand: { create: farmOrLandDataParams.create },
              },
              where: farmOrLandDataParams.where,
            });
          }
          if (farmOrLandDataParams.delete.length > 0) {
            await self.actions.financialApplication.saveFinancialApplication({
              data: {
                parentsOwnedFarmOrLand: { delete: farmOrLandDataParams.delete },
              },
              where: farmOrLandDataParams.where,
            });
          }
        }
        if (type === "submit") params.data.isRegistrationComplete = true;
        console.log(params, "params");
        const { saveFinancialApplication } =
          await self.actions.financialApplication.saveFinancialApplication(
            params
          );
        console.log(saveFinancialApplication, "saveFinancialApplication");
        //
        self.actions.setCurrentFinApp(saveFinancialApplication);
        //
        const phoneNumber = item["WHAT IS YOUR PHONE NUMBER?"];

        if (user && user.id) {
          const userParams = { dateOfBirth: date };
          if (phoneNumber && !user.phones.find((i) => i.number === phoneNumber))
            userParams["phones"] = { create: [{ number: phoneNumber }] };
          const { saveUser } = await this.actions.user.saveUser({
            where: { id: user?.id },
            data: userParams,
          });
        }
        //
        if (saveFinancialApplication?.id) {
          self.actions.alert.showSuccess({
            message:
              type === "submit"
                ? "Saved data successfully"
                : "Data updated successfully",
          });
          if (type === "submit") {
            self.$router.push("/dashboard");
          }
        }
      } catch (e) {
        console.log(e, type, "ERROR");
        this.actions.alert.showError({
          message: "Failed to save data. Please try again.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleScroll(e) {
      console.log("scroll");
    },
  },
  async beforeMount() {
    // const app = this.state.selectedFinApp;
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);

    // if ($("#rootwizard:not(.welcome-wrapper)").length && $("#rootwizard:not(.welcome-wrapper)").offset()) {
    //   const fafsaTop = $("#rootwizard:not(.welcome-wrapper)").offset().top;
    //   if ($(this).scrollTop() >= fafsaTop && !$('#afApplication').hasClass('fix-finapp')) { $('#afApplication').addClass('fix-finapp') } else if ($(this).scrollTop() < fafsaTop && $('#afApplication').hasClass('fix-finapp')) { $('#afApplication').removeClass('fix-finapp') }
    // }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
  